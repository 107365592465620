$(document).ready(function() {
  $(function(){
    $('.slick-mandarinas').slick({
      dots: true,
      speed: 800,
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 3000,
    });
  });

  $(function() {
    $('.navbar-nav').on('click', 'a', function(event) {

      event.preventDefault();

      var id = $(this).data('id');

      $("html, body").animate({
        scrollTop: $('#' + id).offset().top - 80
      }, 1000);
    });
  });

  $(function() {
    $('#form-contact').submit(function(e) {

      e.preventDefault();

      var form = $(this);
      var submit = form.find("input[type='submit']");

      $.ajax({
        url: '/app/mail.php',
        data: form.serialize(),
        type: 'POST',
        beforeSend: function() {
          submit.attr('disabled','disabled');
        },
        success: function() {

          submit.attr('disabled','disabled');

          var alert  = "<div class='alert alert-success'>";
              alert += "Gracias por contactarnos";
              alert += "</div>";

          form.prepend(alert);
        }
      });
    });
  });
});
